export const PADDING_LEFT = '9%';
export const PADDING_RIGHT = '3%';
export const PADDING_BOTTOM = '2%';
export const NO_PADDING = '0';
export const TEXT_ALIGN = 'right';
export const TEXT_ALIGN_CENTER = 'center';
export const FONT_WEIGHT = 'bold';
export const FONT_WEIGHT_DESCRIPTION = '500';
export const COLOR = 'black';
export const COLOR_HEADER = 'white';
export const DISPLAY = 'inline';
export const DISPLAY_NONE = 'none';
export const DISPLAY_BLOCK = 'block';
export const POSITION = 'absolute';
export const POSITION_RELATIVE = 'relative';
export const TOP = 0;
export const BOTTOM = 0;
export const WIDTH = '100%';
export const MARGIN = 'auto';
export const NO_MARGIN = '0';
export const TEXT_DECORATION = 'none';
export const MARGIN_TOP = '0';
export const MARGIN_BOTTOM = '0';
export const VERTICAL_ALIGN = 'middle';
export const VERTICAL_ALIGN_BOTTOM = 'bottom';
export const VERTICAL_ALIGN_BASELINE = 'baseline';
export const VERTICAL_ALIGN_TOP = 'top';

export const LINE_STYLE = {paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT };
export const SECTIONS_STYLE = {paddingBottom: PADDING_BOTTOM, position: POSITION, top: TOP, width: WIDTH};
export const CHEVRON_STYLE = {paddingBottom: PADDING_BOTTOM, position: POSITION, bottom: BOTTOM, width: WIDTH};
export const LINK_STYLE = {textDecoration: TEXT_DECORATION, color: COLOR};
export const LINKS_STYLE = {verticalAlign: VERTICAL_ALIGN, display: DISPLAY, padding: NO_PADDING, margin: NO_MARGIN};
export const LINKS_SVG_SPAN_STYLE = {verticalAlign: VERTICAL_ALIGN_BOTTOM, display: DISPLAY, padding: NO_PADDING, margin: NO_MARGIN, marginRight: '10px', marginLeft: '10px'};
export const NO_DISPLAY = {display: DISPLAY_NONE};
export const NO_TEXT_DECORATION = {textDecoration: TEXT_DECORATION};
export const NO_STYLE = {};

export const DESKTOP_TEXT_ALIGN = 'left';
export const MOBILE_TEXT_ALIGN = 'center';

const EDUCATION = {
    title: 'Education',
    university: 'University of Waterloo',
    degree: 'Bachelor\'s Degree',
    mention: 'Honours',
    major: 'Software Engineering',
    date: 'September 2017 - April 2022',
    nextId: 'projects'
};

export default EDUCATION;
